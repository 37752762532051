import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

export type QuickFilter =
  | "unfiltered_list"
  | "failed_to_auto_allocate"
  | "no_access"
  | "jobsheet_uncompleted"
  | "requires_jobsheet_approval";
export type JobStatusOption = keyof typeof JobStatusFilters;

export type OrderingField = "created_at" | "fulfillment_window_start_date_time";

export type DateRangeFilterType = {
  startDate?: string;
  endDate?: string;
};

type Msg =
  | { kind: "reset" }
  | { kind: "selectQuickFilter"; filter: QuickFilter }
  | { kind: "toggleStatus"; status: JobStatusOption }
  | { kind: "selectStatus"; status: JobStatusOption[] }
  | { kind: "clearStatus" }
  | { kind: "toggleFieldOrder"; field: OrderingField }
  | { kind: "changePage"; page: number }
  | { kind: "changeLimit"; limit: number }
  | { kind: "search"; term: string }
  | { kind: "dateRange"; dateRange: DateRangeFilterType }
  | { kind: "clearSearch" }
  | { kind: "togglePartnerId"; partnerId: string }
  | { kind: "selectPartnerIds"; partnerIds: string[] }
  | { kind: "clearPartnerIds" }
  | { kind: "toggleTagId"; tagId: string };

type State = {
  page: number;
  limit: number;
  searchTerm: string | null;
  quickFilter: QuickFilter;
  jobStatuses: JobStatusOption[];
  orderBy: [OrderingField, "ASC" | "DESC"] | null;
  partnerIds: string[];
  tagIds: string[];
  dateRange: DateRangeFilterType;
};

const defaultState: Readonly<State> = {
  page: 0,
  limit: 25,
  searchTerm: null,
  quickFilter: "unfiltered_list",
  orderBy: ["created_at", "DESC"],
  jobStatuses: [],
  partnerIds: [],
  tagIds: [],
  dateRange: {},
};

function reducer(state: State, msg: Msg): State {
  switch (msg.kind) {
    case "reset":
      return { ...defaultState };
    case "changePage":
      return { ...state, page: msg.page };
    case "changeLimit":
      return { ...state, limit: msg.limit };
    case "search":
      if (msg.term !== state.searchTerm) {
        return { ...state, searchTerm: msg.term, page: defaultState.page };
      }
      return state;
    case "clearSearch":
      return { ...state, searchTerm: null, page: defaultState.page };
    case "selectQuickFilter":
      switch (msg.filter) {
        case "unfiltered_list":
          return { ...defaultState, quickFilter: msg.filter, page: defaultState.page };

        default:
          return {
            ...defaultState,
            quickFilter: msg.filter,
            page: defaultState.page,
          };
      }
    case "toggleStatus": {
      if (state.jobStatuses.includes(msg.status)) {
        return {
          ...state,
          jobStatuses: state.jobStatuses.filter((status) => status !== msg.status),
          page: defaultState.page,
        };
      }

      return { ...state, jobStatuses: [...state.jobStatuses, msg.status], page: defaultState.page };
    }
    case "selectStatus": {
      const set = new Set([...state.jobStatuses, ...msg.status]);
      return {
        ...state,
        jobStatuses: [...set],
        page: defaultState.page,
      };
    }

    case "clearStatus":
      return { ...state, jobStatuses: [], page: defaultState.page };

    // DESC -> ASC -> null is the toggle pattern
    case "toggleFieldOrder": {
      if (!state.orderBy || state.orderBy[0] !== msg.field) {
        return { ...state, orderBy: [msg.field, "DESC"] };
      }

      if (state.orderBy[0] === msg.field) {
        if (state.orderBy[1] === "DESC") {
          return { ...state, orderBy: [msg.field, "ASC"] };
        }
      }

      return { ...state, orderBy: null };
    }

    case "togglePartnerId":
      if (state.partnerIds.includes(msg.partnerId)) {
        return {
          ...state,
          partnerIds: state.partnerIds.filter((id) => id !== msg.partnerId),
          page: defaultState.page,
        };
      }

      return {
        ...state,
        partnerIds: [...state.partnerIds, msg.partnerId],
        page: defaultState.page,
      };

    case "selectPartnerIds": {
      const set = new Set([...state.partnerIds, ...msg.partnerIds]);
      return {
        ...state,
        partnerIds: [...set],
        page: defaultState.page,
      };
    }

    case "clearPartnerIds":
      return { ...state, partnerIds: [], page: defaultState.page };
    case "dateRange":
      if (msg.dateRange !== state.dateRange) {
        return { ...state, dateRange: msg.dateRange, page: defaultState.page };
      }
      return state;
    case "toggleTagId":
      if (state.tagIds.includes(msg.tagId)) {
        return {
          ...state,
          tagIds: state.tagIds.filter((tagId) => tagId !== msg.tagId),
          page: defaultState.page,
        };
      }

      return { ...state, tagIds: [...state.tagIds, msg.tagId] };

    default:
      const _shouldNeverHappen: never = msg;
  }

  return state;
}

export const JobStatusFilters = {
  booked: "Booked",
  unbooked: "Unbooked",
  unallocated: "Unallocated",
  unassigned: "Unassigned",
  failed_to_auto_allocate: "Failed to autoallocate",
  submitted: "Completed",
  no_access: "No access",
  jobsheet_approval_required: "Awaiting Jobsheet Approval",
  pending_payment: "Pending Payment",
  flagged_for_jobsheet_approval: "Flagged for Jobsheet Approval",
  include_deleted: "Include Cancelled",
} as const;

type WithDispatch<State, Msg> = State & {
  dispatch: (msg: Msg) => void;
};

const dummyStorageApi = {
  getItem: () => null,
  setItem: () => undefined,
} as unknown as typeof window.sessionStorage;

export const useJobFilterStore = create(
  persist<WithDispatch<State, Msg>>(
    (set) => ({
      ...defaultState,
      dispatch: (msg: Msg) => set((state) => reducer(state, msg)),
    }),
    {
      name: "jobFilters",
      version: 2,
      storage: createJSONStorage(() =>
        typeof window !== "undefined" ? window.sessionStorage : dummyStorageApi,
      ),
    },
  ),
);

export const useJobFilterDispatch = () => useJobFilterStore((state) => state.dispatch);
